import { Helmet } from 'react-helmet-async';

function HomePage() {
  return (
    <>
      <Helmet>
        <title>Best Auto Rickshaw Services in Coimbatore</title>
        <meta name="description" content="Coimbatore’s leading auto rickshaw, and taxi services. Safe, reliable transportation with top-notch vehicles and trained drivers. Available 24 hours." />
        <meta name="keywords" content="Coimbatore cabs, cabs in Coimbatore, call taxi service Coimbatore, auto rickshaw Coimbatore, Coimbatore auto manufacturers, Coimbatore auto parts, 24 hours auto services Coimbatore, taxi services Coimbatore, car rental Coimbatore, Coimbatore travel agents, Safesafari app" />
        <link rel="canonical" href="https://safesafari.in/" />
        <script type="application/ld+json">
            {`
            {
                "@context": "http://schema.org",
                "@type": "LocalBusiness",
                "name": "Coimbatore Cabs and Auto Services",
                "description": "Leading cabs, call taxi, and auto rickshaw services in Coimbatore. Available 24/7 for local and outstation travel.",
                "url": "https://safesafari.in/",
                "address": {
                "@type": "PostalAddress",
                "addressLocality": "Coimbatore",
                "addressRegion": "TN",
                "postalCode": "641001",
                "addressCountry": "IN"
                },
                "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91 75300 15700",
                "contactType": "Customer Service",
                "areaServed": "Coimbatore"
                },
                "keywords": "Coimbatore cabs, call taxi Coimbatore, auto rickshaw Coimbatore, Coimbatore auto services"
            }
            `}
        </script>
      </Helmet>
    </>
  );
}

export default HomePage;
