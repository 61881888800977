// src/components/Subscribe.js
import React from 'react';

const Subscribe = () => {
  return (
    <div className="bg-white py-10 px-6 text-center shadow-md ">
      <h3 className="text-xl font-bold text-gray-800  font-sans ">Experience the Difference – Subscribe Now!</h3>
      <p className="mt-2 text-gray-500 font-sans ">Don’t miss out on the fun and convenience that awaits you. Together, let’s create memorable experiences and make the most of every ride. Subscribe now and elevate your journey with us!</p>
      <button className="mt-4 px-6 py-2 bg-red-500 text-white font-semibold rounded-lg font-sans ">Subscribe Now</button>
    </div>
  );
};

export default Subscribe;