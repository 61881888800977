import "@fontsource/roboto"; // Defaults to weight 400
import './App.css';
import Home from './pages/home.js';
import HowItWorks from './pages/how_to_work.js';
import PricingCard from './components/price.js';
import AboutUS from './pages/about_us.js';
import ContactUS from './pages/contact_us.js';
import Subscribe from './components/subscribe.js';

import useResponsive from './hooks/useResponsive';


import MobileHome from './mobile_view/Home.js';
import MobileHowItWorks from './mobile_view/how_its_work.js';
import MobilePrice from './mobile_view/price.js';
import MobileAboutUS from './mobile_view/mobile_about_us.js';
import MobileContactUS from './mobile_view/contact_us.js';
import SEO from './pages/seo.js';

function App() {
  const { isMobile, isTablet } = useResponsive();
  return (
    <div className="App">
      <SEO />
       {/* Call the ImageWithText component */}
       {isMobile || isTablet ? (
        <>
          <MobileHome />
          <MobileHowItWorks />
          <MobilePrice />
          <MobileAboutUS />
          <MobileContactUS />
        </>
      ) : (
        <>
          <Home />
          <HowItWorks />
          <PricingCard />
          <AboutUS/>
          <Subscribe />
          <ContactUS />
        </>
      )}
    </div>
  );
}

export default App;
