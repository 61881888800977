import React from 'react';
import '../App.css';
import Carousel from '../components/carousel';
import Footer from '../components/footer';

const WaveBackground = () => {

  return (
    <div id="contact-us" className="wave-container">
      <svg
        className="wave"
        viewBox="0 0 1200 400"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <defs>
          <linearGradient id="grad1" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" style={{ stopColor: '#a8e6cf', stopOpacity: 1 }} />
            <stop offset="100%" style={{ stopColor: '#d0fddf', stopOpacity: 1 }} />
          </linearGradient>
        </defs>
        <path
          fill="url(#grad1)"
          d="M0,200 C300,50 900,350 1200,150 L1200,400 L0,400 Z"
          opacity="0.5"
        />
        <path
          fill="url(#grad1)"
          d="M0,300 C300,100 900,400 1200,250 L1200,400 L0,400 Z"
          opacity="0.3"
        />
        <path
          fill="url(#grad1)"
          d="M0,350 C300,200 900,450 1200,300 L1200,400 L0,400 Z"
          opacity="0.1"
        />
      </svg>

      <div className='relative flex mt-24 ml-10'>
        <div className="z-10 text-center items-start max-w-3xl">
          <h3 className="text-sm font-semibold text-gray-500 font-sans">REVIEW READER</h3>
          <h2 className="mt-2 text-3xl font-bold text-gray-800 font-sans">What People Say About Us.</h2>
          <p className="mt-4 text-gray-500 max-w-md mx-auto font-sans">
            The contents of a book are its different chapters and sections, usually shown at the beginning of the book.
          </p>
        </div>
      </div>
      <Carousel />
      <Footer />
    </div>
  );
};

export default WaveBackground;
