import React, { useState } from 'react';
import PhoneImage from '../assets/img/phone_auto.png'; // Replace with your actual image path
import DriverImage from '../assets/img/driver_auto.png';

const HowItWorks = () => {
  const [activeTab, setActiveTab] = useState("Customers");
  return (
    <section className="flex flex-col items-center py-16 px-4 bg-white absolute top-0 left-0 right-0 bg-gradient-to-r rounded-tl-[2rem] rounded-tr-[2rem] mt-[35rem] mb-[43rem] sm:mt-[28rem] sm:mb-[28rem]">
      {/* Section Heading */}
      <h2 className="text-3xl sm:text-4xl font-bold mb-4 text-center">How Safe Safari Works</h2>
      <p className="text-center text-gray-600 max-w-xl mb-8">
        Download Safe Safari driver app from the Play Store, create an account, use your auto, and drive by yourself. Get rides and earn more money.
      </p>

      {/* Tab Buttons */}
      <div className="flex space-x-1 border border-gray-300 rounded-lg overflow-hidden w-max mb-8">
        <button
          onClick={() => setActiveTab("Customers")}
          className={`px-4 py-2 ${
            activeTab === "Customers"
              ? "bg-green-400 text-black"
              : "bg-white text-gray-800"
          } rounded-l-lg`}
        >
          Customers
        </button>
        <button
          onClick={() => setActiveTab("Captains")}
          className={`px-4 py-2 ${
            activeTab === "Captains"
              ? "bg-green-400 text-black"
              : "bg-white text-gray-800"
          } rounded-r-lg`}
        >
          Driver
        </button>
      </div>

      {/* Conditional Content */}
      {activeTab === "Captains" && (
        <div className="w-full flex flex-col items-center gap-12">
        <div className="flex flex-col lg:flex-row items-center justify-center gap-12">
          {/* Step 1 */}
          <div className="flex flex-col items-center text-center max-w-xs">
            <div className="bg-green-100 text-green-600 rounded-full w-16 h-16 flex items-center justify-center mb-4">
              <span className="text-2xl font-bold font-sans">1</span>
            </div>
            <h3 className="text-xl font-semibold mb-2 font-sans">Register and Verify Your Identity</h3>
            <p className="text-gray-600 font-sans">
              Join the Safe Safari Driver App with a secure registration process. Easily upload your valid proof documents (Driving License, PAN Card, Aadhaar Card).
            </p>
          </div>
      
          {/* Image */}
          <div className="relative">
            <img src={DriverImage} alt="Phone" className="w-64 lg:w-80" />
          </div>
      
          {/* Step 2 */}
          <div className="flex flex-col items-center text-center max-w-xs">
            <div className="bg-green-100 text-green-600 rounded-full w-16 h-16 flex items-center justify-center mb-4">
              <span className="text-2xl font-bold font-sans">2</span>
            </div>
            <h3 className="text-xl font-semibold mb-2 font-sans">Set Your Own Fare Rate</h3>
            <p className="text-gray-600">
              You’re in control! Set your price per kilometer based on the distance, allowing you to earn what you deserve. Adjust rates as needed to maximize your earnings.
            </p>
          </div>
        </div>
      
        <div className="flex flex-col lg:flex-row items-center justify-center gap-12 -mt-5">
          {/* Step 3 */}
          <div className="flex flex-col items-center text-center max-w-xs">
            <div className="bg-green-100 text-green-600 rounded-full w-16 h-16 flex items-center justify-center mb-4">
              <span className="text-2xl font-bold font-sans">3</span>
            </div>
            <h3 className="text-xl font-semibold mb-2 font-sans">Stay Ready for Customer Requests</h3>
            <p className="text-gray-600 font-sans">
              Once your registration is complete and verified, you're all set. Just wait for ride requests from customers, and once you receive one, you’re good to go!
            </p>
          </div>
      
          {/* Step 4 */}
          <div className="flex flex-col items-center text-center max-w-xs">
            <div className="bg-green-100 text-green-600 rounded-full w-16 h-16 flex items-center justify-center mb-4">
              <span className="text-2xl font-bold font-sans">4</span>
            </div>
            <h3 className="text-xl font-semibold mb-2 font-sans">Start the Ride and Enjoy the Journey</h3>
            <p className="text-gray-600 font-sans">
              Accept the request, pick up your passenger, and start the ride with peace of mind. Our platform is designed to keep both drivers and customers safe and satisfied.
            </p>
          </div>
        </div>
      </div>
      
      )}

      {activeTab === "Customers" && (
        <div className="w-full flex flex-col items-center gap-12">
        <div className="flex flex-col sm:flex-row items-center justify-center gap-8">
          {/* Step 1 */}
          <div className="flex flex-col items-center text-center max-w-xs px-4">
            <div className="bg-green-100 text-green-600 rounded-full w-12 h-12 sm:w-16 sm:h-16 flex items-center justify-center mb-4">
              <span className="text-xl sm:text-2xl font-bold">1</span>
            </div>
            <h3 className="text-lg sm:text-xl font-semibold mb-2 font-sans">Request a Trip</h3>
            <p className="text-gray-600 font-sans">
              Easily select your pickup and drop-off locations using our intuitive interface. Choose from various trip types—whether it's a quick ride, a longer journey, or a special request, we’ve got you covered!
            </p>
          </div> 

          {/* Image */}
          <div className="relative">
            <img src={PhoneImage} alt="Phone" className="w-52 sm:w-64" />
          </div>
      
          {/* Step 2 */}
          <div className="flex flex-col items-center text-center max-w-xs px-4">
            <div className="bg-green-100 text-green-600 rounded-full w-12 h-12 sm:w-16 sm:h-16 flex items-center justify-center mb-4">
              <span className="text-xl sm:text-2xl font-bold font-sans">2</span>
            </div>
            <h3 className="text-lg sm:text-xl font-semibold mb-2">Match with a Driver</h3>
            <p className="text-gray-600 font-sans">
              Our smart algorithm connects you with the nearest available driver within minutes. Enjoy peace of mind knowing that each driver is vetted for safety and reliability, ensuring a secure journey.
            </p>
          </div>
        </div>
      
        <div className="flex flex-col sm:flex-row items-center justify-center gap-8 mt-7">
          {/* Step 3 */}
          <div className="flex flex-col items-center text-center max-w-xs px-4">
            <div className="bg-green-100 text-green-600 rounded-full w-12 h-12 sm:w-16 sm:h-16 flex items-center justify-center mb-4">
              <span className="text-xl sm:text-2xl font-bold font-sans">3</span>
            </div>
            <h3 className="text-lg sm:text-xl font-semibold mb-2 font-sans">Enjoy Your Trip</h3>
            <p className="text-gray-600 font-sans">
              Experience a comfortable ride with real-time GPS tracking to guide you. Your driver will ensure a smooth journey while you sit back, relax, and enjoy the sights along the way!
            </p>
          </div>
      
          {/* Step 4 */}
          <div className="flex flex-col items-center text-center max-w-xs px-4">
            <div className="bg-green-100 text-green-600 rounded-full w-12 h-12 sm:w-16 sm:h-16 flex items-center justify-center mb-4">
              <span className="text-xl sm:text-2xl font-bold font-sans">4</span>
            </div>
            <h3 className="text-lg sm:text-xl font-semibold mb-2 font-sans">Pay and Rate</h3>
            <p className="text-gray-600">
              Complete your payment effortlessly using cash or card. After your ride, we encourage you to rate your driver and share your feedback, helping us improve our service continuously!
            </p>
          </div>
        </div>
      </div>
      
      )}
    </section>
  );
};

export default HowItWorks;
