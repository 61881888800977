import React, { useState, useEffect } from 'react';
import mobile_auto from '../assets/img/logo.png';
import '@fortawesome/fontawesome-free/css/all.min.css';

const testimonials = [
  {
    text: "This is an amazing product! It has changed my life for the better. I love using this every day. It's simply the best.",
    image: "/path/to/image1.jpg",
    name: "John Doe",
    location: "New York, USA",
    rating: 3,
  },
  {
    text: "Excellent service and support. Highly recommend! I love using this every day. It's simply the best.",
    image: "/path/to/image2.jpg",
    name: "Jane Smith",
    location: "London, UK",
    rating: 4,
  },
  {
    text: "I love using this every day. It's simply the best. Excellent service and support. Highly recommend!",
    image: "/path/to/image3.jpg",
    name: "Mike Johnson",
    location: "Sydney, Australia",
    rating: 5,
  },
];

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, 5000);
    return () => clearInterval(interval);
  }, [currentIndex]);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
    );
  };

  const renderStars = (rating) => {
    return Array(5)
      .fill()
      .map((_, index) => (
        <i
          key={index}
          className={`fas fa-star ${index < rating ? 'text-yellow-500' : 'text-gray-300'}`}
        ></i>
      ));
  };

  return (
    <div className="relative w-full -m-36 ml-[28rem] shadow-green-200/50">
      <div className="relative overflow-hidden rounded-lg max-w-md mx-auto">
        {testimonials.map((testimonial, index) => (
          <div
            key={index}
            className={`testimonial-slide ${index === currentIndex ? 'block' : 'hidden'} duration-700 ease-in-out`}
          >
            <div className="bg-green-50 p-6 rounded-lg shadow-lg max-w-sm mx-auto relative">
              <p className="text-gray-600 text-center font-sans">{testimonial.text}</p>
              <div className="flex items-center mt-4">
                <img
                  src={mobile_auto}
                  alt={testimonial.name}
                  className="w-10 h-10 rounded-full"
                />
                <div className="ml-3 text-left">
                  <h4 className="font-semibold text-gray-800 font-sans">{testimonial.name}</h4>
                  <div className="flex">
                    {renderStars(testimonial.rating)}
                  </div>
                </div>
              </div>

              <button
                type="button"
                onClick={handlePrev}
                className="absolute top-1/2 left-0 px-2 py-1"
              >
                <svg className="w-4 h-4 text-white dark:text-gray-300 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 1 1 5l4 4"/>
                </svg>
              </button>
              <button
                type="button"
                onClick={handleNext}
                className="absolute top-1/2 right-0 px-2 py-1"
              >
                <svg className="w-4 h-4 text-white dark:text-gray-300 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4"/>
                </svg>
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Carousel;
