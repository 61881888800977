
import React from 'react';

const PricingComponent = () => {
  return (
    <section id="pricing" className="bg-white text-white min-h-screen flex flex-col items-center p-8 mt-[50rem] relative overflow-hidden">
      {/* Floating Background and Headings */}
      <div className="flex flex-col items-center mb-16">
        
        <div className="absolute inset-x-0 top-0 h-80 bg-gradient-to-b from-green-100 to-transparent rounded-b-full"></div>

        {/* Title Inside Floating Background */}
        <h2 className="text-green-600 text-4xl font-semibold mb-4 text-center z-10 font-sans ">Flexible Plans to Suit Your Needs</h2>
        <p className="text-gray-400 text-center mb-8 z-10 font-sans ">Whether you're a casual driver or ready to hit the road full-time, pick a plan that aligns with your goals.</p>
        <div className="flex flex-col items-center justify-center text-center px-4">
          {/* Thumbs-up Emoji */}
          <div className="realtive z-10 text-6xl mb-4 font-sans ">👍</div>
          
          {/* Main Heading */}
          <h1 className="text-2xl md:text-4xl font-bold text-green-600 mb-2 font-sans ">We're Still Cooking Our Website.</h1>
          
          {/* Subtext */}
          <p className="text-gray-500 text-lg md:text-xl mb-6 font-sans ">
            We are going to launch our website Very Soon. <br />
            Stay Tuned.
          </p>
          
          {/* Notify Me Button */}
          <button className="flex items-center justify-center gap-2 bg-green-400/20 text-green-700 text-lg px-6 py-3 rounded-full hover:bg-green-500 hover:text-white transition duration-300">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 12a4 4 0 01-8 0 4 4 0 018 0zm-7-6a9 9 0 1018 0 9 9 0 00-18 0zm9-5h-.01M15 7h-3m2-2a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            Coming Soon...
          </button>
        </div>

        <div className="absolute inset-x-0 bottom-0 h-80 bg-gradient-to-t from-green-100 to-transparent rounded-t-full"></div>
        <div className="z-10 text-center" style={{ marginTop: '72px' }}>
          <h3 className="text-sm font-semibold text-gray-500 mb-3 font-sans ">Price Notes</h3>
          <p className="mt-4 text-gray-500 max-w-md mx-auto font-sans ">
            Get clear, upfront pricing on all services – no surprises. Transparent, easy-to-understand rates for every journey.
          </p>
        </div>
      </div>
    </section>
  );
};

export default PricingComponent;