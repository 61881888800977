import React, { useState } from 'react';

const PricingComponent = () => {
  const [billingCycle, setBillingCycle] = useState('annually');

  return (
    <section className="bg-white text-white min-h-screen flex flex-col items-center p-8 mt-[85rem] relative overflow-hidden">
      {/* Floating Background and Headings */}
      <div className="flex flex-col items-center mb-16">
        
        <div className="absolute inset-x-0 top-0 h-80 bg-gradient-to-b from-green-100 to-transparent rounded-b-full"></div>

        {/* Title Inside Floating Background */}
        <h2 className="text-green-600 text-4xl font-semibold mb-4 text-center z-10">Flexible Plans to Suit Your Needs</h2>
        <p className="text-gray-400 text-center mb-8 z-10">Whether you're a casual driver or ready to hit the road full-time, pick a plan that aligns with your goals.</p>

        {/* Toggle for Billing Cycle */}
        {/* <div className="flex items-center space-x-4 mb-8 z-10">
          <span 
            className={`cursor-pointer ${billingCycle === 'monthly' ? 'text-white' : 'text-gray-400'}`}
            onClick={() => setBillingCycle('monthly')}
          >
            MONTHLY
          </span>
          <div className="bg-gray-700 w-12 h-6 rounded-full flex items-center justify-between p-1 cursor-pointer" onClick={() => setBillingCycle(billingCycle === 'monthly' ? 'annually' : 'monthly')}>
            <div className={`h-4 w-4 bg-white rounded-full transition-transform ${billingCycle === 'annually' ? 'translate-x-6' : ''}`} />
          </div>
          <span 
            className={`cursor-pointer ${billingCycle === 'annually' ? 'text-white' : 'text-gray-400'}`}
            onClick={() => setBillingCycle('annually')}
          >
            ANNUALLY <span className="text-yellow-400">Save 20%</span>
          </span>
        </div> */}

        {/* Pricing Cards as Carousel on Mobile */}
        {/* <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-5xl relative overflow-x-auto md:overflow-visible whitespace-nowrap md:whitespace-normal snap-x snap-mandatory"> */}
          {/* Base Plan */}
          {/* <div className="p-8 rounded-lg flex flex-col items-center color-shift-animated snap-center min-w-[280px] md:min-w-0">
            <h3 className="text-2xl font-semibold">Base</h3>
            <p className="text-4xl font-bold mb-4">FREE</p>
            <ul className="text-center space-y-2 mb-6 text-gray-400">
              <li>Calendar and Scheduling</li>
              <li>Broadcasts</li>
              <li>Conferencing</li>
            </ul>
            <button className="bg-gray-700 px-6 py-2 rounded-full text-gray-400 cursor-default">Selected Plan</button>
          </div> */}

          {/* Premium Plan */}
          {/* <div className="p-8 rounded-lg flex flex-col items-center border-4 border-yellow-400 relative color-shift-animated snap-center min-w-[280px] md:min-w-0">
            <div className="absolute top-0 -mt-5 bg-yellow-400 text-black px-4 py-1 rounded-full font-semibold">Most Popular</div>
            <h3 className="text-2xl font-semibold">Premium</h3>
            <p className="text-4xl font-bold mb-4">${billingCycle === 'annually' ? '10' : '12'}/month</p>
            <ul className="text-center space-y-2 mb-6">
              <li>Calendar and Scheduling</li>
              <li>Conferencing</li>
              <li>Unlimited Data Retention</li>
            </ul>
            <button className="bg-yellow-400 px-6 py-2 rounded-full text-black">Upgrade to Premium</button>
          </div> */}

          {/* Unlimited Plan */}
          {/* <div className="p-8 rounded-lg flex flex-col items-center color-shift-animated snap-center min-w-[280px] md:min-w-0">
            <h3 className="text-2xl font-semibold">Unlimited</h3>
            <p className="text-4xl font-bold mb-4">${billingCycle === 'annually' ? '25' : '30'}/month</p>
            <ul className="text-center space-y-2 mb-6">
              <li>Unlimited Plans</li>
              <li>Unlimited Events</li>
              <li>Unlimited Video Conferencing</li>
            </ul>
            <button className="bg-green-500 px-6 py-2 rounded-full text-black">Upgrade to Unlimited</button>
          </div> */}
        {/* </div> */}
        <div className="flex flex-col items-center justify-center text-center">
          <div className="text-6xl mb-4 font-sans">👍</div>
          <h1 className="text-lg text-gray-500 mb-2 font-sans">WE'RE STILL</h1>
          <h2 className="text-3xl font-bold text-green-600 mb-4 font-sans">Cooking Our Website.</h2>
          <p className="text-gray-600 mb-8 font-sans">
            We are going to launch our website <br /> Very Soon. Stay Tuned.
          </p>
          <button className="flex items-center px-6 py-3 bg-green-400/20 text-green-700 font-sans rounded-full shadow-lg hover:bg-green-500 hover:text-white">
            Coming soon..
          </button>
        </div>

        <div className="absolute inset-x-0 bottom-0 h-80 bg-gradient-to-t from-green-100 to-transparent rounded-t-full"></div>
        <div className="z-10 text-center" style={{ marginTop: '72px' }}>
          <h3 className="text-sm font-semibold text-gray-500 mb-3 font-sans">Price Notes</h3>
          <p className="mt-4 text-gray-500 max-w-md mx-auto font-sans">
            The contents of a book are its different chapters and sections, usually shown at the beginning of the book.
          </p>
        </div>
      </div>
    </section>
  );
};

export default PricingComponent;
