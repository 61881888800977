import React from "react";
import m_auto from "../assets/img/bg-3.jpg";
import { useState } from 'react';
import TypewriterEffect from "../hooks/typewriteeffect.js";
import logo from "../assets/img/logo.png";
import logo_1 from "../assets/img/logo_1.png";

const LandingPage = () => {
    const [isOpen] = useState(false);
    return (
        <div 
            className="min-h-screen" 
            style={{ 
                backgroundImage: `url(${m_auto})`, 
                backgroundSize: 'cover', 
                backgroundPosition: 'center' 
            }}
        >
            {/* Navbar */}
            <nav className="flex items-center py-6 px-4">
                {/* Left Side Logo */}
                <div className="flex items-center">
                    <img src={logo} alt="Logo" className="w-10 h-10" />
                    <img src={logo_1} alt="Logo"className="w-40 h-30 -mb-5 mr-3" />
                </div>

                <div className={`flex-col md:flex md:flex-row items-center justify-center p-4 bg-white/10 rounded-lg backdrop-blur-md ${isOpen ? 'flex' : 'hidden'} md:space-x-16 text-gray-700 font-roboto font-bold -mt-6 ml-52`}>
                {/* <div className={`flex-col md:flex md:flex-row items-center justify-center p-4 bg-white/10 rounded-lg backdrop-blur-md ${isOpen ? 'flex' : 'hidden'} md:space-x-16 text-gray-700 font-roboto font-bold -mt-6`}> */}
                    <a href="#how-it-works" className="hover:text-green-600 text-sm sm:text-base font-sans">How It's Work</a>
                    <a href="#pricing" className="hover:text-green-600 text-sm sm:text-base font-sans">Pricing</a>
                    <a href="#our-service" className="hover:text-green-600 text-sm sm:text-base font-sans">Our Services</a>
                    <a href="#contact-us" className="hover:text-green-600 text-sm sm:text-base font-sans">Contact US</a>
                </div>

                {/* Right Side Get Started Button */}
                <div>
                    <a href="#get-started" className="bg-green-600 text-white px-4 py-2 rounded-lg hover:bg-green-700 transition duration-200 ml-96 font-sans">
                        Get Started
                    </a>
                </div>
            </nav>

            {/* Hero Section */}
            <section className="px-4 md:px-8 mt-12 lg:mt-24 flex flex-col lg:flex-row items-center lg:items-start justify-between">
                
                {/* Transparent Box Wrapper */}
                <div className="lg:w-[45%] flex flex-col items-center text-center mt-3 mr-9 bg-white/10 p-6 rounded-lg backdrop-blur-md">
                
                    <header className="p-5 text-center text-green-700">
                    <h1 className="text-4xl font-sans font-bold">Welcome to Coimbatore’s Premier Auto Services</h1>
                    </header>
                    {/* Main Heading */}
                    <div className="w-full h-full flex justify-center items-center">
                        <h1 id="typewriter" className="text-3xl sm:text-4xl lg:text-5xl xl:text-6xl font-bold text-gray-900 mb-4"><TypewriterEffect /></h1>
                    </div>

                    {/* Subtext */}
                    <p className="mt-4 text-gray-500 text-base sm:text-lg max-w-xl mb-6 font-sans">
                        Download Safe Safari app! Available on the App Store and Play Store, drive your Auto solo, accept ride requests, and maximize your earnings!
                    </p>

                    {/* Store Buttons */}
                    <div className="flex space-x-4">
                        <button type="button" class="flex items-center justify-center w-48 mt-3 text-white bg-black rounded-lg h-14">
                            <div class="mr-3">
                                <svg viewBox="30 336.7 120.9 129.2" width="30">
                                    <path fill="#FFD400" d="M119.2,421.2c15.3-8.4,27-14.8,28-15.3c3.2-1.7,6.5-6.2,0-9.7  c-2.1-1.1-13.4-7.3-28-15.3l-20.1,20.2L119.2,421.2z">
                                    </path>
                                    <path fill="#FF3333" d="M99.1,401.1l-64.2,64.7c1.5,0.2,3.2-0.2,5.2-1.3  c4.2-2.3,48.8-26.7,79.1-43.3L99.1,401.1L99.1,401.1z">
                                    </path>
                                    <path fill="#48FF48" d="M99.1,401.1l20.1-20.2c0,0-74.6-40.7-79.1-43.1  c-1.7-1-3.6-1.3-5.3-1L99.1,401.1z">
                                    </path>
                                    <path fill="#3BCCFF" d="M99.1,401.1l-64.3-64.3c-2.6,0.6-4.8,2.9-4.8,7.6  c0,7.5,0,107.5,0,113.8c0,4.3,1.7,7.4,4.9,7.7L99.1,401.1z">
                                    </path>
                                </svg>
                            </div>
                            <div>
                                <div class="text-xs">
                                    GET IT ON
                                </div>
                                <div class="-mt-1 font-sans text-xl font-semibold">
                                    Google Play
                                </div>
                            </div>
                        </button>
                        <button type="button" class="flex items-center justify-center w-48 mt-3 text-white bg-black h-14 rounded-xl">
                            <div class="mr-3">
                                <svg viewBox="0 0 384 512" width="30">
                                    <path fill="currentColor" d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z">
                                    </path>
                                </svg>
                            </div>
                            <div>
                                <div class="text-xs">
                                    Download on the
                                </div>
                                <div class="-mt-1 font-sans text-xl font-semibold">
                                    App Store
                                </div>
                            </div>
                        </button>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default LandingPage;