// src/components/Testimonial.js
import React from 'react';
import abot_us from '../assets/img/about_us.png'; 
import mobile_auto from '../assets/img/logo.png'; 
import '../App.css';

const AboutUS = () => {
  return (
    <div id='our-service' className="relative py-20 bg-white">
      {/* Arch Background */}
      <div className="absolute inset-x-0 top-0 h-80 bg-gradient-to-b from-green-100 to-transparent rounded-b-full"></div>
      
      {/* Heading and Subtitle */}
      <h2 className="relative z-10 -mt-28 text-center text-3xl font-bold text-gray-800 font-sans">Our Services</h2>
      <p className="relative z-10 mt-4 text-center text-gray-500 max-w-md mx-auto font-sans">
        Your reliable partner for convenient and affordable three-wheel auto taxi services!
      </p>

      {/* Main Content */}
      <div className="relative z-10 flex flex-col lg:flex-row items-center max-w-6xl mx-auto mt-8">
        
        {/* Image on the Left */}
        <div className="flex-1">
          <img src={abot_us} alt="About Us" className="w-64 lg:w-80" />
        </div>

        {/* Content on the Right */}
        <div className="flex-1 text-center lg:text-left mt-10 lg:mt-0 lg:ml-8 space-y-6">
          <h2 className="text-2xl lg:text-3xl font-bold text-gray-800 font-sans">Ride with Ease and Confidence!</h2>
          
          <p className="text-gray-600 text-lg leading-relaxed">
            Welcome to <span className="text-green-600 font-semibold font-sans">Safe Safari</span>, your reliable and convenient auto-rickshaw booking app! With just a few taps, your journey starts. Select your <span className="text-black font-semibold">pickup</span> and <span className="text-black font-semibold">drop-off</span> locations, and we’ll connect you with a trusted driver nearby for a seamless experience.
          </p>
          
          <p className="text-black text-lg leading-relaxed">
            <span className="text-black font-semibold font-sans">Transparent pricing</span> is our priority. With Safe Safari, you’ll know the cost upfront based on distance, ensuring no surprises along the way. Our focus is on providing <span className="text-yellow-600 font-semibold">safe, efficient</span>, and <span className="text-yellow-600 font-semibold">affordable rides</span> every time.
          </p>
          
          <p className="text-gray-600 text-lg leading-relaxed font-sans">
            Complete your payment hassle-free with <span className="text-black font-semibold">UPI</span> or <span className="text-black font-semibold">cash</span> after each ride. Join Safe Safari and experience a new level of convenience, where every journey is just as smooth as the next. Your ride, your way – experience Safe Safari today!
          </p>
        </div>
        
      </div>

      {/* Second Row: Content Left and Image Right */}
      <div className="relative z-10 flex flex-col lg:flex-row items-center lg:items-start max-w-6xl mx-auto mt-10">
        <div className="flex-1 text-center lg:text-left mt-6 lg:mt-0 lg:mr-8 space-y-6">
          <h2 className="text-2xl lg:text-3xl font-bold text-gray-800 font-sans">Drive Your Way with Safe Safari: Secure, Flexible, and Rewarding</h2>
          
          <p className="text-gray-600 text-lg leading-relaxed font-sans">
            Joining the <span className="font-bold text-gray-800">Safe Safari Driver App</span> is easy, secure, and puts you in control of your driving career. Start by registering and uploading essential proof of identity documents like your <span className="font-semibold text-gray-800">Driving License</span>, <span className="font-semibold text-gray-800">PAN Card</span>, or <span className="font-semibold text-gray-800">Aadhaar Card</span>.
          </p>

          <div className="bg-green-50 p-4 rounded-lg shadow-md">
            <h3 className="text-xl font-semibold text-gray-800 font-sans">Flexible Earnings</h3>
            <p className="text-gray-600 text-lg leading-relaxed font-sans">
              Once verified, you’re ready to take the wheel and set your own fare rate per kilometer. Enjoy the freedom to grow your income while driving on your terms.
            </p>
          </div>

          <p className="text-gray-600 text-lg leading-relaxed font-sans">
            Ready to take control of your driving career? <span className="font-bold text-gray-800">Join Safe Safari today!</span>
          </p>
        </div>
        <div className="flex-1 mt-6 lg:mt-0 pl-6">
          <img src={mobile_auto} alt="About Us" className="w-64 lg:w-80" />
        </div>
      </div>


    </div>
  );
};

export default AboutUS;
